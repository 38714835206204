/* eslint-disable camelcase,no-undef */
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  Layout,
  Container,
  RequestContainer,
  RequestText,
  RequestCardWrapper,
  RequestCard,
  MainSectionView2,
  IconsContainerV2,
  ViewContainerV2,
  FilterIcon1,
  DividerLine,
  StyledInputV2,
  SearchFilter1,
  CustomDropDownV2,
  SearchFilterContainer,
  BuddyTabContainer,
  BuddyTab1,
  Heading,
  /*AddEmployeeButton,*/ ManageButton,
  CustomDropDownWellness /*UpdateLocationButton*/,
  CustomDropDownItem,
  SearchIcon,
  DropdownTitle,
  BottomCard,
} from "./styles";
import { PaginationCircleNew } from "../Journeys/styles";
import { UserButton } from "../EngagementSection/styles";
import PeopleListView from "../PeopleListViewV2/communityListView";
import PeopleGridView from "../PeopleGridViewV2/communityGridView";
import Waiting from "../Waiting";
import NewEmployeePopup from "../NewEmployeePopupV2";
import CSVMaker from "../CSVMaker";
import CSVList from "../../components/PeopleHomeV2/CSVList";
// import { StyledModal,  ButtonV2, TopDiv, BottomDiv,InviteTitle,ConfirmationText ,CenteredImage,WelcomeImage,} from './ManageBuddies/styles';
import {
  RemoveCSVData,
  FetchEmployeeCount,
  DeleteEmployeeAPI,
  FetchSetRemoveAPI,
  PostAddEmployee,
  fetchPeopleAdmin,
  getListOfEmployees,
  getSortedEmployees,
  uploadBiometricForm,
  viewPhysicianFormAPI,
  getUserRoles,
  getCompanyDetails,
  getFriendRequests,
  acceptOrRejectFriendRequest,
  getWellnessInterests,
  getCompanyLocation,
} from "../../redux/actions";
import ReactPDF from "../../components/ReactPDF";
import { getPermissionStatus } from "../../utils/methods";
const AddRolesPopup = React.lazy(() => import("../AddRolesPopup"));
const EditUserDetailsPopup = React.lazy(() => import("./EditUserDetailsPopup"));
import { withTranslation } from "react-i18next";
import { ImageUrl } from "../../utils/constants";
import {
  editDepartmentApi,
  editLocationApi,
  sendFriendRequest,
} from "../../redux/actions/peopleHomeActions";
// import { peoplePagePoints } from '../../../mockData';
const AddLocationPopup = React.lazy(() =>
  import("../NewEmployeePopupV2/locationPopup")
);
const AddDepartmentPopup = React.lazy(() =>
  import("../NewEmployeePopupV2/departmentPopup")
);
const ParticipantDepartmentPopup = React.lazy(() =>
  import("../NewEmployeePopupV2/participantsDepartment")
);
import {
  FieldTitleV2,
  StyledBodyV2,
  StyledHeader,
  StyledmodalV2,
  PopupHeaderNew,
} from "../NewEmployeePopupV2/styles";
import HoverButton from "../common/HoverButton";
import {
  AddEmployeeIcon,
  EmployeeCountIcon,
  SearchButtonIcon,
  ListIcon,
  ListColorIcon,
  GridrIcon,
  PrintNewIcon,
  GridrColorIcon,
  PaginationIcon,
  UpdateDepartmentIcon,
  UpdateLocationIcon,
  DropDownIcon,
} from "../../utils/icons";
const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;

const userType = [
  {
    name: "All Users",
    id: 0,
  },
  {
    name: "Employee",
    id: 1,
  },
  {
    name: "Spouse",
    id: 2,
  },
  {
    name: "Dependent",
    id: 3,
  },
];
const headers = [
  { label: "First Name", key: "fname" },
  { label: "LastName", key: "lname" },
];

class PeoplePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: null,
      department: null,
      departmentId: null,
      engagement: "",
      alphabetical: null,
      alphabeticalId: "",
      search: "",
      showPopUp: false,
      showGrid: props.showView,
      companyId: props.companyInfo["id"],
      engagementSort: false,
      showSort: false,
      pageNo: 1,
      loading: false,
      minLimit: 1,
      maxLimit: "",
      total: "",
      countingValues: "",
      locationDropDown: null,
      locationDropDownId: "",
      loader: false,
      showPDFModal: false,
      buttonText: "",
      pdfBase64: "",
      uploadUid: null,
      showDeleteUserPopUp: false,
      selectUserDropDown: "All Users",
      selectUserDropDownId: 0,
      showRolesPopup: false,
      showUserPopUp: false,
      wellnessInterest: null,
      wellnessInterestId: "",
      userId: null,
      initiativeId: null,
      initiativeName: null,
      wellnessSearch: false,
      peoplePage: null,
      peoplePageId: "",
      showLocation: false,
      showDepartment: false,
      participantLocation: false,
      participantDepartment: false,
      activeButton: false,
      selectedParticipant: [],
      showNote: false,
      NoteText: "Location",
      showTeams: false,
      redirectToTop: false,
      welcomePopUp: false,
    };
    this.timer = null;
  }

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null,
    });
  };

  handleWelcomePopUp = (value) => {
    this.setState({ welcomePopUp: value });
  };

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { paginationCount } = prevProps;
    if (paginationCount && this.state.countingValues !== paginationCount) {
      this.setState(
        {
          total: Math.ceil(paginationCount / 20),
          countingValues: paginationCount,
        },
        () => {
          if (this.state.total < 6) {
            this.setState({
              maxLimit: this.state.total,
            });
          } else {
            this.setState({
              maxLimit: 6,
            });
          }
        }
      );
    }
  }

  nextPageDetail = (e) => {
    e.preventDefault();
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    if (bottom) {
      this.setState(
        {
          pageNo: this.state.pageNo + 1,
        },
        () => {
          if (this.props.count > this.props.employeeData.length) {
            this.fetchEmployee();
          }
        }
      );
    }
  };

  componentDidMount() {
    this.props.fetchListOfEmployees();
    const {
      getCompanyDetails,
      fetchFriendRequests,
      getWellnessInterests,
      searchAmigo,
    } = this.props;
    this.fetchEmployee();
    const { companyId } = this.state;
    !searchAmigo && getCompanyDetails(companyId);
    searchAmigo && fetchFriendRequests();
    getWellnessInterests();
    getCompanyLocation(companyId, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.redirectToTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ redirectToTop: false });
      }, 3000);
    }
    if (prevState.companyId !== this.state.companyId) {
      this.setState(
        {
          search: "",
          pageNo: 1,
          engagementSort: false,
          showSort: false,
          department: null,
          departmentId: null,
          alphabetical: null,
          alphabeticalId: "",
        },
        () => {
          this.fetchEmployee();
        }
      );
    }
  }

  changeOrder = () => {
    this.setState(
      {
        engagementSort: !this.state.engagementSort,
        showSort: true,
        pageNo: 1,
        selectedEmployee: null,
        alphabetical: null,
      },
      () => {
        this.fetchEmployee();
      }
    );
  };

  setorRemoveAction = (uid, status) => {
    const { fetchSetRemoveAPI } = this.props;
    let obj = {};
    obj["uid"] = uid;
    obj["status"] = !status ? 1 : 0;
    obj["company_id"] = this.state.companyId;
    obj["role"] = 11;
    fetchSetRemoveAPI(obj);
  };

  onSearchFunction = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchEmployee();
    }, WAIT_INTERVAL);
  };

  handleKeyDown = (evt) => {
    if (evt.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.fetchEmployee();
    }
  };

  changeLoading = () => {
    this.setState({
      loading: false,
    });
  };

  fetchEmployee = () => {
    this.setState({
      uploadUid: null,
    });
    let obj = {};
    const {
      pageNo,
      companyId,
      search,
      engagementSort,
      showSort,
      alphabeticalId,
      departmentId,
      department,
      locationDropDown,
      locationDropDownId,
      selectUserDropDownId,
      wellnessInterest,
      wellnessSearch,
      peoplePageId,
    } = this.state;
    if (pageNo === 1) {
      this.setState({ loading: true });
    } else {
      this.changeLoading();
    }
    const { fetchPeopleAdmin /*, fetchEmployeeCount*/ } = this.props;
    if (peoplePageId !== "") {
      if (peoplePageId !== "all") {
        obj["point_sort"] = peoplePageId;
      }
    }
    obj["company_id"] = companyId;
    obj["name"] = search && search.trim();
    obj["page_no"] = pageNo;
    obj["limit"] = 20;
    if (wellnessInterest && wellnessSearch) {
      obj["interest_id"] = wellnessInterest;
    }
    if (showSort) {
      if (engagementSort) {
        obj["engagement_sort"] = "DESC";
      } else {
        obj["engagement_sort"] = "ASC";
      }
    } else {
      if (alphabeticalId === 1) {
        obj["name_sort"] = "ASC";
      } else if (alphabeticalId === 2) {
        obj["name_sort"] = "DESC";
      }
    }
    if (selectUserDropDownId === 0) {
      obj["user_type"] = "all";
    }
    if (selectUserDropDownId === 1) {
      obj["user_type"] = 0;
    }
    if (selectUserDropDownId === 2) {
      obj["user_type"] = 1;
    }
    if (selectUserDropDownId === 3) {
      obj["user_type"] = 2;
    }
    if (departmentId && department !== "All Department") {
      obj["department_id"] = departmentId;
    }

    if (locationDropDownId && locationDropDown !== "All Location") {
      obj["city_state_id"] = locationDropDownId;
    }
    // if (pageNo === 1 && !showSort && search === '' && !departmentId && !alphabeticalId && !locationDropDownId) {
    //   fetchEmployeeCount(obj, true);
    // } else {
    //   fetchEmployeeCount(obj, false);
    // }
    fetchPeopleAdmin(obj, this.changeLoading);
    this.setState({
      selectedEmp: null,
      selectedEmployee: null,
    });
  };

  fetchEmployeePost = (uid) => {
    this.setState({
      uploadUid: null,
    });
    let obj = {};
    const {
      pageNo,
      companyId,
      search,
      engagementSort,
      showSort,
      alphabeticalId,
      departmentId,
      department,
      locationDropDown,
      locationDropDownId,
      selectUserDropDownId,
    } = this.state;
    const { fetchEmployeeCount, sendFriendRequest } = this.props;
    obj["company_id"] = companyId;
    obj["name"] = search && search.trim();
    obj["page_no"] = pageNo;
    obj["limit"] = 20;
    if (showSort) {
      if (engagementSort) {
        obj["engagement_sort"] = "DESC";
      } else {
        obj["engagement_sort"] = "ASC";
      }
    } else {
      if (alphabeticalId === 1) {
        obj["name_sort"] = "ASC";
      } else if (alphabeticalId === 2) {
        obj["name_sort"] = "DESC";
      }
    }
    if (selectUserDropDownId === 0) {
      obj["user_type"] = "all";
    }
    if (selectUserDropDownId === 1) {
      obj["user_type"] = 0;
    }
    if (selectUserDropDownId === 2) {
      obj["user_type"] = 1;
    }
    if (selectUserDropDownId === 3) {
      obj["user_type"] = 2;
    }
    if (departmentId && department !== "All Department") {
      obj["department_id"] = departmentId;
    }

    if (locationDropDownId && locationDropDown !== "All Location") {
      obj["city_state_id"] = locationDropDownId;
    }
    if (
      pageNo === 1 &&
      !showSort &&
      search === "" &&
      !departmentId &&
      !alphabeticalId &&
      !locationDropDownId
    ) {
      fetchEmployeeCount(obj, false);
    } else {
      fetchEmployeeCount(obj, false);
    }
    sendFriendRequest(uid, obj);
  };

  deleteUserAction = (uid) => {
    const { deleteEmployeeAPI } = this.props;
    let obj = {};
    obj["user_uid"] = uid;
    deleteEmployeeAPI(obj);
    this.setState({
      selectedEmployee: null,
    });
  };

  getCompany = (value) => {
    if (!this.state.loading) {
      const { companies, setCompany } = this.props;
      const id = companies.filter(
        (company) => company.company_name === value
      )[0].id;
      this.setState({
        companyId: id,
        pageNo: 1,
        selectedEmployee: null,
        countingValues: "",
        minLimit: 1,
        maxLimit: "",
        departmentId: null,
        locationDropDownId: null,
      });
      setCompany(id, value);
    }
  };

  addEmployeePopUp = () => {
    this.setState({
      showPopUp: !this.state.showPopUp,
    });
  };

  updateLocationPopUp = () => {
    this.setState({
      showLocation: !this.state.showLocation,
    });
  };

  addParticipantsLocation = () => {
    let participant = JSON.parse(localStorage.getItem("selectedEmployeeList"));
    this.setState({
      participantLocation: !this.state.participantLocation,
      selectedParticipant: participant,
      activeButton: !this.state.activeButton,
    });
  };

  addParticipantsDepartment = () => {
    let participant = JSON.parse(localStorage.getItem("selectedEmployeeList"));
    this.setState({
      participantDepartment: !this.state.participantDepartment,
      selectedParticipant: participant,
    });
  };

  updateDepartmentPopUp = () => {
    this.setState({
      showDepartment: !this.state.showDepartment,
    });
  };

  userModal = () => {
    this.setState((prevState) => ({
      showUserPopUp: !prevState.showUserPopUp,
    }));
  };

  showEmployeeDetails = (id) => {
    if (!this.state.loading) {
      this.setState({
        selectedEmployee: id,
      });
    }
  };

  updateDropdown = (title, id, titleKey, idKey) => {
    this.setState(
      {
        [titleKey]: title,
        [idKey]: id,
        pageNo: 1,
        engagementSort: false,
        showSort: false,
      },
      () => {
        this.fetchEmployee();
      }
    );
  };

  updatePoints = (title, id, titleKey, idKey) => {
    this.setState(
      {
        [titleKey]: title,
        [idKey]: id,
      },
      () => {
        this.fetchEmployee();
      }
    );
  };

  updateWellnessDropdown = (title, id, titleKey, idKey) => {
    if (title != "All") {
      this.setState({
        wellnessSearch: true,
      });
    } else {
      this.setState({
        wellnessSearch: false,
      });
    }
    this.setState(
      {
        [titleKey]: title,
        [idKey]: id,
        pageNo: 1,
        engagementSort: false,
        showSort: false,
      },
      () => {
        this.fetchEmployee();
      }
    );
  };

  onSelect = (value, state) => {
    this.setState(
      {
        [state]: value,
      },
      () => {
        const { department, engagement, alphabetical, search } = this.state;
        this.sortEmployees(search, engagement, department, alphabetical);
      }
    );
  };

  onChange = (e) => {
    e.preventDefault();
    if (!this.state.loading) {
      this.setState(
        {
          search: e.target.value,
          pageNo: 1,
          engagementSort: false,
          showSort: false,
        },
        () => {
          this.onSearchFunction();
          const { department, engagement, alphabetical, search } = this.state;
          this.sortEmployees(search, engagement, department, alphabetical);
        }
      );
    }
  };

  sortEmployees = (search, engagement, department, alphabetical) => {
    this.props.fetchSortedEmployees(
      search,
      engagement,
      department,
      alphabetical
    );
  };

  showView = (value) => {
    this.setState({
      showGrid: value,
    });
  };

  getMenuItems = () => {
    const { companies } = this.props;
    return companies && companies.map((company) => company.company_name);
  };

  getPaginationCircle = () => {
    let circleButton = [];
    for (let i = this.state.minLimit; i <= this.state.maxLimit; i++) {
      circleButton.push(
        <PaginationCircleNew
          key={i}
          active={i == this.state.pageNo}
          onClick={() => this.setPagination(i)}
        >
          <div>{i}</div>
        </PaginationCircleNew>
      );
    }
    return circleButton;
  };

  setPagination = (activeIndex) => {
    this.setState({
      redirectToTop: true,
    });
    if (activeIndex !== this.state.pageNo) {
      if (this.state.total <= 6) {
        this.setState(
          {
            minLimit: 1,
            maxLimit: this.state.total,
            pageNo: activeIndex,
          },
          () => {
            this.fetchEmployee();
          }
        );
      } else {
        if (activeIndex === 1) {
          this.setState(
            {
              pageNo: activeIndex,
              maxLimit: 6,
              minLimit: 1,
            },
            () => {
              this.fetchEmployee();
            }
          );
        } else {
          if (activeIndex + 3 > this.state.total) {
            this.setState(
              {
                pageNo: activeIndex,
                maxLimit: this.state.total,
                minLimit: this.state.total - 4,
              },
              () => {
                this.fetchEmployee();
              }
            );
          } else {
            this.setState(
              {
                pageNo: activeIndex,
                maxLimit: activeIndex + 3,
                minLimit: activeIndex - 1,
              },
              () => {
                this.fetchEmployee();
              }
            );
          }
        }
      }
    }
  };

  changeLoader = (bool) => {
    this.setState({
      loader: bool,
    });
  };

  uploadPDFFile = (e, id) => {
    let file = e.target.files[0];
    if (file) {
      let fileArr = file.name.split(".");
      if (fileArr[fileArr.length - 1].toUpperCase() == "PDF") {
        if (file.size / 1000000 <= 10) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              showPDFModal: true,
              pdfBase64: reader.result,
              buttonText: "Submit",
              uploadUid: id,
            });
          };
        } else {
          toast.error("Please select PDF file less than 10MB");
          this.changeLoader(false);
          document.getElementById("biometric-user-admin-file").value = "";
        }
      } else {
        toast.error("Please select PDF file");
        this.changeLoader(false);
        document.getElementById("biometric-user-admin-file").value = "";
      }
    } else {
      this.changeLoader(false);
      document.getElementById("biometric-user-admin-file").value = "";
    }
  };

  removePDF = () => {
    this.setState({
      showPDFModal: false,
      pdfBase64: "",
      buttonText: "",
      uploadUid: null,
    });
    document.getElementById("biometric-user-admin-file").value = "";
  };

  closePopup = () => {
    const { uploadUid, pdfBase64 } = this.state;
    if (pdfBase64.trim() != "" && uploadUid) {
      this.changeLoader(true);
      this.props.uploadBiometricForm(
        { physician_form: pdfBase64 },
        this.changeLoader,
        uploadUid,
        this.fetchEmployee
      );
      this.setState({
        showPDFModal: false,
      });
    }
  };

  showPDFData = (id) => {
    const { viewPhysicianFormAPI } = this.props;
    this.changeLoader(true);
    viewPhysicianFormAPI(id, this.changeLoader);
  };

  onToggleDeletePopup = () => {
    this.setState((prev) => ({
      showDeleteUserPopUp: !prev.showDeleteUserPopUp,
    }));
  };
  onToggleRolesPopup = (uid) => {
    this.setState(
      (prev) => ({ showRolesPopup: !prev.showRolesPopup }),
      () => {
        this.state.showRolesPopup && this.props.getUserRoles(uid);
      }
    );
  };

  acceptOrRejectRequest = (id, status) => {
    const { acceptOrRejectFriendRequest } = this.props;
    acceptOrRejectFriendRequest({ request_id: id, status: status });
  };

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  showManage = () => {
    const { history } = this.props;
    history.push("/community/manage-buddies");
  };

  showInternalText = () => (
    <FieldTitleV2
      paddingTop={1}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "15px",
      }}
    >
      All The Employee added to your selected {this.state.NoteText}.
    </FieldTitleV2>
  );

  notePopup = () => (
    <StyledmodalV2 bulkWidth left show={this.state.showNote}>
      <StyledHeader closeButton>
        <PopupHeaderNew>
          <div className="headingTitle">Note</div>
          <div className="closeButton">
            <img
              src="/public/images/new_close_icon.png"
              alt=""
              style={{ cursor: "pointer", height: "20px", width: "20px" }}
              onClick={() => {
                this.handleShowNote("");
              }}
            />
          </div>
        </PopupHeaderNew>
      </StyledHeader>
      <StyledBodyV2>{this.showInternalText()}</StyledBodyV2>
    </StyledmodalV2>
  );

  handleShowNote = (text) => {
    this.setState({
      showNote: !this.state.showNote,
      NoteText: text,
    });
    this.fetchEmployee();
  };

  hideShowTeam = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status });
    if (status) {
      history.push("/company/people/team");
    }
  };
  hideShowInactiveUsers = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status });
    if (status) {
      history.push("/company/people/inactive-users");
    }
  };

  printAction = async (bool) => {
    await this.props.fetchPeopleAdmin({company_id: this.props.companyInfo.id, page_no: 1, limit: this.props.count, user_type: "all"}, ()=>{});
    this.setState({
      printAttendees: bool,
    });
  };

  render() {
    const {
      employeeList,
      companyInfo,
      employeeData,
      downloadCSV,
      csvData,
      removeCSVData,
      paginationCount,
      searchAmigo,
      userRolesList,
      count,
      companyDetails,
      friendRequests,
      wellnessInterests,
      history,
      t,
      role,
      userCompany,
    } = this.props;
    const {
      selectedEmployee,
      showPopUp,
      loader,
      showPDFModal,
      pdfBase64,
      buttonText,
      showRolesPopup,
      showUserPopUp,
      userId,
      initiativeId,
      initiativeName,
      showLocation,
      showDepartment,
      participantLocation,
      activeButton,
      participantDepartment,
      showTeams,
      printAttendees,
    } = this.state;
    if (
      _.isUndefined(employeeList) ||
      _.isNull(employeeList) ||
      !employeeData ||
      _.isUndefined(friendRequests) ||
      _.isUndefined(wellnessInterests)
    ) {
      return <Waiting />;
    }
    let selectedEmp = false;
    let selectedEmployeeDetail = false;
    if (
      !_.isUndefined(employeeData) &&
      !_.isNull(employeeData) &&
      employeeData.length > 0
    ) {
      selectedEmp = selectedEmployee ? selectedEmployee : employeeData[0].uid;
      selectedEmployeeDetail = employeeData.filter(
        (employee) => employee.uid === selectedEmp
      );
    } else {
      selectedEmp = false;
    }
    let WellnessInterestAll = [];
    WellnessInterestAll = [{ name: "All Interests", id: "0" }];
    let WellnessInterests = [];
    if (
      wellnessInterests &&
      !_.isUndefined(wellnessInterests) &&
      !_.isNull(wellnessInterests) &&
      wellnessInterests.length > 0
    ) {
      WellnessInterests = [...WellnessInterestAll, ...wellnessInterests];
    }


    // window.console.log("employeeData",employeeData);
    // window.console.log("location data is",this.props.locationDetails, this.props.departmentDetails )
    return (
     
      <Layout>
        {loader && <Waiting />}
        <Container>
          {this.state.loading && <Waiting />}
          {
            <BuddyTabContainer
              show={activeButton}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <BuddyTab1
                  active={showTeams ? false : true}
                  style={{ width: "auto", padding: "0 10px" }}
                >
                  {searchAmigo ? t("Buddies") : t("Active")}
                </BuddyTab1>
                {!searchAmigo &&
                  (role === "ADMIN" ||
                    role === "WELLNESS_ADMIN" ||
                    role === "WELLNESS_CHAMPION" ||
                    companyInfo.id === 1 ||
                    companyInfo.id === 18) && (
                  <BuddyTab1
                    active={showTeams ? true : false}
                    onClick={() => this.hideShowInactiveUsers(true)}
                    style={{ width: "auto", padding: "0 15px" }}
                  >
                    {" "}
                    {t("Inactive")}
                  </BuddyTab1>
                )}
                {!searchAmigo &&
                (role === "ADMIN" ||
                  role === "WELLNESS_ADMIN" ||
                  role === "WELLNESS_CHAMPION" ||
                  (userCompany && userCompany.showTeam)) ? (
                    <BuddyTab1
                      active={showTeams ? true : false}
                      onClick={() => this.hideShowTeam(true)}
                    >
                      {" "}
                      {t("Teams")}
                    </BuddyTab1>
                  ) : null}
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ViewContainerV2>
                  {
                    !searchAmigo &&
                      this.props.departmentDetails &&
                      this.props.departmentDetails != "" &&
                      this.props.departmentDetails != null &&
                      this.props.departmentDetails.length > 0 && (
                      <HoverButton
                        onClick={this.updateDepartmentPopUp}
                        title={"Manage Departments"}
                        svgPath={UpdateDepartmentIcon()}
                        activeColor={"#007AB1"}
                        fillOpacity={"1"}
                      />
                    )
                    //<UpdateLocationButton background='#F6B479' onClick={this.updateDepartmentPopUp}>
                    //{t("Update Department")}
                    //</UpdateLocationButton>
                  }
                  {
                    !searchAmigo &&
                      this.props.locationDetails &&
                      this.props.locationDetails.length > 0 && (
                      <HoverButton
                        onClick={this.updateLocationPopUp}
                        title={"Manage Locations"}
                        svgPath={UpdateLocationIcon()}
                        fillOpacity={1}
                        activeColor={"#007AB1"}
                      />
                    )
                    //<UpdateLocationButton onClick={this.updateLocationPopUp}>
                    //{t("Update Location")}
                    //</UpdateLocationButton>
                  }

                  {
                    !searchAmigo &&
                      getPermissionStatus(
                        "Add Employee",
                        this.props.userPermissions
                      ) && (
                      <div style={{ marginRight: "10px" }}>
                        <HoverButton
                          onClick={this.addEmployeePopUp}
                          title={"Add Employees"}
                          svgPath={AddEmployeeIcon()}
                          activeColor={"#007AB1"}
                          fillOpacity={"1"}
                        />
                      </div>
                    )
                    //<AddEmployeeButton onClick={this.addEmployeePopUp}>
                    //{t("Add New Employee")}
                    //</AddEmployeeButton>
                  }
                  <div style={{ margin: "0 10px 0 0", cursor: "pointer" }}>
                    <HoverButton
                      title={"Download"}
                      onClick={() => this.printAction(true)}
                      marginLeft={"0px"}
                      fillOpacity={1}
                      disabled={false}
                      backgroundColor={"#f0f6f8"}
                      backgroundColorActive={"#005c87"}
                      activeColor={"#007AB1"}
                      inActiveColor={"white"}
                      svgPath={<PrintNewIcon />}
                    />
                  </div>
                  <IconsContainerV2>
                    <div
                      className={`listIcon ${
                        this.state.showGrid ? "" : "active"
                      }`}
                      onClick={() => this.showView(false)}
                    >
                      {this.state.showGrid ? ListColorIcon() : ListIcon()}
                    </div>
                    <div
                      className={`gridIcon ${
                        this.state.showGrid ? "active" : ""
                      }`}
                      onClick={() => this.showView(true)}
                    >
                      {this.state.showGrid ? GridrIcon() : GridrColorIcon()}
                    </div>
                  </IconsContainerV2>
                </ViewContainerV2>
              </div>
              {/* {!searchAmigo && <div style={{width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <div className='radio-btn' onClick={  
                  this.addParticipantsLocation} >
                  <div className='innerCircle'></div>
                </div>
                <div className='bulk-bttn'>
                  Bulk Selection
                </div>
              </div>} */}
              {searchAmigo && (
                <ManageButton
                  width="125px"
                  height="40px"
                  onClick={() => this.showManage()}
                >
                  {t("Manage")}
                </ManageButton>
              )}
            </BuddyTabContainer>
          }
          <FilterIcon1>
            <SearchFilter1>
              <StyledInputV2
                type="text"
                value={this.state.search}
                placeholder={t("Search users, teams, etc")}
                onChange={(e) => this.onChange(e)}
                onKeyDown={this.handleKeyDown}
              />
              <SearchIcon> {SearchButtonIcon()}</SearchIcon>
            </SearchFilter1>

            <SearchFilterContainer
              scroll={
                this.props.departmentDetails &&
                !_.isNull(this.props.departmentDetails) &&
                this.props.departmentDetails.length > 15
                  ? 1
                  : 0
              }
              hasDepartmentData={this.props.departmentDetails && this.props.departmentDetails.length > 0}
              hasLocationData={this.props.locationDetails && this.props.locationDetails.length > 0}
            >
              <div className="filterTitle">Filters :</div>
              {/* {!searchAmigo &&
                    <CustomDropDownV2 inputdisplay="block" inputpadding="0px 15px"
                      title={<div className="title">{t(this.state.peoplePage) || t('Points')}</div>}
                      id="role-sort"
                    >
                      {
                        peoplePagePoints.map((list, index) => (
                          <CustomDropDownItem key={index} onSelect={() => this.updatePoints(list.points, list.id, 'peoplePage', 'peoplePageId')}>{list.points}</CustomDropDownItem>
                        ))
                      }
                    </CustomDropDownV2>} */}
              <div className="community-department">
                {!searchAmigo &&
                  (this.state.selectUserDropDownId == 0 ||
                    this.state.selectUserDropDownId == 3 ||
                    this.state.selectUserDropDownId == 2 ||
                    this.state.selectUserDropDownId == 1) && (
                  <CustomDropDownV2
                    inputdisplay="block"
                    inputpadding="0px"
                    title={
                      <DropdownTitle>
                        <div className="title">
                          {this.state.selectUserDropDown || "User Type"}
                        </div>
                        <div className="arrow-icon"> {DropDownIcon()}</div>
                      </DropdownTitle>
                    }
                    id="role-sort"
                    onSelect={(index /*key*/) => {
                      this.updateDropdown(
                        userType[index].name,
                        userType[index].id,
                        "selectUserDropDown",
                        "selectUserDropDownId"
                      );
                    }}
                  >
                    {userType.map((list, index) => (
                      /*(index===0||index===1||(companyDetails && companyDetails.length > 0 && companyDetails[0] && companyDetails[0].show_spouse===1?index===3:'')||(companyDetails && companyDetails.length > 0 && companyDetails[0] && companyDetails[0].show_dependent===1?index===2:''))&&*/
                      <CustomDropDownItem key={index} eventKey={index}>
                        {list.name}
                      </CustomDropDownItem>
                    ))}
                  </CustomDropDownV2>
                )}
              </div>
              {this.props.departmentDetails && this.props.departmentDetails.length > 1 ? (     
                <div className="community-department">
                  {(this.state.selectUserDropDownId == 0 ||
                  this.state.selectUserDropDownId == 3 ||
                this.state.selectUserDropDownId == 2 ||
                this.state.selectUserDropDownId == 1) &&
                this.props.departmentDetails &&
                this.props.departmentDetails.length && (
                    <CustomDropDownV2
                      inputdisplay="block"
                      inputpadding="0px"
                      type="button"
                      title={
                        <DropdownTitle>
                          <div className="title">
                            {this.state.department || "All Departments"}
                          </div>
                          <div className="arrow-icon"> {DropDownIcon()}</div>
                        </DropdownTitle>
                      }
                      id="department-sort"
                      onSelect={(index /*key*/) =>
                        this.updateDropdown(
                          this.props.departmentDetails[index].department,
                          this.props.departmentDetails[index].id,
                          "department",
                          "departmentId"
                        )
                      }
                    >
                      {this.props.departmentDetails.map((list, index) => (
                        <CustomDropDownItem key={index} eventKey={index}>
                          {t(list.department)}
                        </CustomDropDownItem>
                      ))}
                    </CustomDropDownV2>
                  )}
                </div>): null}
              {this.props.locationDetails && this.props.locationDetails.length > 1  ? ( 
                <div className="community-department">
                  {(this.state.selectUserDropDownId == 0 ||
                this.state.selectUserDropDownId == 3 ||
                this.state.selectUserDropDownId == 1 ||
                this.state.selectUserDropDownId == 2) &&
                this.props.locationDetails &&
                this.props.locationDetails.length && (
                    <CustomDropDownV2
                      inputdisplay="block"
                      inputpadding="0px"
                      title={
                        <DropdownTitle>
                          <div className="title">
                            {t(this.state.locationDropDown) ||
                              t("All Locations")}
                          </div>
                          <div
                            style={{ marginLeft: "10px" }}
                            className="arrow-icon"
                          >
                            {" "}
                            {DropDownIcon()}
                          </div>
                        </DropdownTitle>
                      }
                      id="role-sort"
                      onSelect={(index /*key*/) =>
                        this.updateDropdown(
                          this.props.locationDetails[index].state &&
                            this.props.locationDetails[index].city
                            ? this.props.locationDetails[index].city +
                                ", " +
                                this.props.locationDetails[index].state
                            : this.props.locationDetails[index].state ===
                                null && this.props.locationDetails[index].city
                              ? this.props.locationDetails[index].city
                              : this.props.locationDetails[index].state &&
                                    this.props.locationDetails[index].city === null
                                ? this.props.locationDetails[index].state
                                : null,
                          this.props.locationDetails[index].id,
                          "locationDropDown",
                          "locationDropDownId"
                        )
                      }
                    >
                      {this.props.locationDetails.map((list, index) => (
                        <CustomDropDownItem key={index} eventKey={index}>
                          {t(
                            list.state && list.city
                              ? list.city + ", " + list.state
                              : list.state === null && list.city
                                ? list.city
                                : list.state && list.city === null
                                  ? list.state
                                  : list.location
                          )}
                        </CustomDropDownItem>
                      ))}
                    </CustomDropDownV2>
                  )}
                </div>):null }
   
                  
              <div className="wellness-interest">
                {(this.state.selectUserDropDownId == 0 ||
                  this.state.selectUserDropDownId == 3 ||
                  this.state.selectUserDropDownId == 1 ||
                  this.state.selectUserDropDownId == 2) &&
                  wellnessInterests &&
                  wellnessInterests.length > 0 && (
                  <CustomDropDownWellness
                    inputdisplay="block"
                    inputpadding="0px"
                    title={
                      <DropdownTitle>
                        <div className="title">
                          {t(this.state.wellnessInterestId) ||
                            t("All Interests")}
                        </div>
                        <div
                          style={{ marginLeft: "10px" }}
                          className="arrow-icon"
                        >
                          {" "}
                          {DropDownIcon()}
                        </div>
                      </DropdownTitle>
                    }
                    id="role-sort"
                    onSelect={(index /*key*/) => {
                      this.updateWellnessDropdown(
                        WellnessInterests[index].name,
                        WellnessInterests[index].id,
                        "wellnessInterestId",
                        "wellnessInterest"
                      );
                    }}
                  >
                    {WellnessInterests.map((list, index) => (
                      <CustomDropDownItem key={index} eventKey={index}>
                        {t(list.name)}
                      </CustomDropDownItem>
                    ))}
                  </CustomDropDownWellness>
                )}
              </div>
            </SearchFilterContainer>
          </FilterIcon1>

          {
            <BottomCard>
              <div>
                {/* <LazyImage div={'profile'} src={ImageUrl + "/images/PeoplePage/People_Group_Img.png"} alt="people_icon" /> */}
                {/* <employeeIcon/> */}
                <div className="profile">{EmployeeCountIcon()}</div>
                <div className="count">
                  {count || 0} {searchAmigo ? t("Buddies") : t("Active Users")}
                </div>
              </div>
              <DividerLine />
            </BottomCard>
          }

          {friendRequests && friendRequests.length > 0 && searchAmigo && (
            <RequestContainer background="white">
              <RequestText>{t("Buddy Requests Recieved")}</RequestText>
              <RequestCardWrapper>
                {friendRequests &&
                  friendRequests.length > 0 &&
                  friendRequests.map((request, index) => (
                    <RequestCard key={index}>
                      <div className="profile">
                        <img
                          onError={this.fallBack}
                          src={`${ImageUrl}/${request.profile_image}`}
                          alt={"Profile"}
                        />
                      </div>
                      <div className="info">
                        <div className="name">{t(request.fullname)}</div>
                        <div className="wellness-icon">
                          {request.wellness_interest &&
                            request.wellness_interest.length > 0 &&
                            request.wellness_interest.map(
                              (wellness, index) =>
                                index < 5 && (
                                  <div
                                    key={index}
                                    onMouseEnter={() =>
                                      this.onEnterMouse(
                                        request.id,
                                        wellness.interest_id,
                                        wellness.interest_name
                                      )
                                    }
                                    onMouseLeave={() => this.onLeaveMouse()}
                                  >
                                    <img
                                      src={`${ImageUrl}/${wellness.interest_icon}`}
                                    />
                                    {userId === request.id &&
                                      initiativeId === wellness.interest_id && (
                                      <div className="hover-card">
                                        <div> {t(initiativeName)}</div>
                                      </div>
                                    )}
                                  </div>
                                )
                            )}
                        </div>
                      </div>
                      <div className="last-card">
                        <div
                          className="confirm"
                          onClick={() =>
                            this.acceptOrRejectRequest(request.id, "Accepted")
                          }
                        >
                          {t("Confirm")}
                        </div>
                        <div
                          className="cancel"
                          onClick={() =>
                            this.acceptOrRejectRequest(request.id, "Rejected")
                          }
                        >
                          {t("Cancel")}
                        </div>
                      </div>
                    </RequestCard>
                  ))}
              </RequestCardWrapper>
            </RequestContainer>
          )}
          {searchAmigo && (
            <Heading>
              {t(
                "Connect with colleagues who share similar roles or wellness interests"
              )}
            </Heading>
          )}
          {
            <div style={{ width: "100%", display: "flex" }}>
              <MainSectionView2>
                {this.state.showGrid ? (
                  <PeopleGridView
                    employeeList={employeeData}
                    showEmployeeDetails={this.showEmployeeDetails}
                    selectedEmployee={selectedEmp}
                    searchAmigo={searchAmigo}
                    sendFriendRequestPost={this.fetchEmployeePost}
                    history={history}
                    show={activeButton}
                    plp={{
                      companyId: this.state.companyId,
                      fetchEditLocationApi: this.props.fetchEditLocationApi,
                      show: participantLocation,
                      data: this.props.locationDetails,
                      departmentData: this.props.departmentDetails,
                      showModalPopUp: this.addParticipantsLocation,
                      companyAuthDetails: companyDetails,
                      handleShowNote: this.handleShowNote,
                    }}
                  />
                ) : (
                  <PeopleListView
                    employeeList={employeeData}
                    showEmployeeDetails={this.showEmployeeDetails}
                    selectedEmployee={selectedEmp}
                    changeOrder={this.changeOrder}
                    searchAmigo={searchAmigo}
                    sendFriendRequestPost={this.fetchEmployeePost}
                    history={history}
                    show={activeButton}
                    plp={{
                      companyId: this.state.companyId,
                      fetchEditLocationApi: this.props.fetchEditLocationApi,
                      show: participantLocation,
                      data: this.props.locationDetails,
                      departmentData: this.props.departmentDetails,
                      showModalPopUp: this.addParticipantsLocation,
                      companyAuthDetails: companyDetails,
                      handleShowNote: this.handleShowNote,
                    }}
                  />
                )}
                {paginationCount > 21 && (
                  <div className="pagination">
                    {this.state.pageNo > 1 && (
                      <UserButton
                        width="32px"
                        height="32px"
                        Align={1}
                        padding="4px"
                        float={1}
                        fontSize="14px"
                        bgColor="rgba(0, 122, 177, 0.05)"
                        capitalize={1}
                        onClick={() =>
                          this.setPagination(this.state.pageNo - 1)
                        }
                      >
                        <div
                          style={{
                            transform: "rotate(180deg)",
                            marginLeft: "20px",
                          }}
                        >
                          {" "}
                          {PaginationIcon()}
                        </div>
                      </UserButton>
                    )}
                    {this.getPaginationCircle()}
                    {this.state.pageNo != this.state.total && (
                      <UserButton
                        width="32px"
                        height="32px"
                        Align={1}
                        padding="4px"
                        fontSize="14px"
                        bgColor="rgba(0, 122, 177, 0.05)"
                        capitalize={1}
                        onClick={() =>
                          this.setPagination(this.state.pageNo + 1)
                        }
                      >
                        <div> {PaginationIcon()} </div>
                      </UserButton>
                    )}
                  </div>
                )}
              </MainSectionView2>
            </div>
          }
        </Container>
        {!searchAmigo && (
          <NewEmployeePopup
            companyId={this.state.companyId}
            postAddEmployee={this.props.postAddEmployee}
            show={showPopUp}
            showModalPopUp={this.addEmployeePopUp}
            companyAuthDetails={companyDetails}
          />
        )}
        {!searchAmigo && showLocation && (
          <Suspense fallback={<Waiting />}>
            <AddLocationPopup
              companyId={this.state.companyId}
              fetchEditLocationApi={this.props.fetchEditLocationApi}
              show={showLocation}
              data={this.props.locationDetails}
              showModalPopUp={this.updateLocationPopUp}
              companyAuthDetails={companyDetails}
            />
          </Suspense>
        )}
        {!searchAmigo && showDepartment && (
          <Suspense fallback={<Waiting />}>
            <AddDepartmentPopup
              companyId={this.state.companyId}
              fetchEditDepartmentApi={this.props.fetchEditDepartmentApi}
              show={showDepartment}
              data={this.props.departmentDetails}
              showModalPopUp={this.updateDepartmentPopUp}
              companyAuthDetails={companyDetails}
            />
          </Suspense>
        )}

        {!searchAmigo && (
          <Suspense fallback={<Waiting />}>
            <ParticipantDepartmentPopup
              companyId={this.state.companyId}
              fetchEditDepartmentApi={this.props.fetchEditDepartmentApi}
              show={participantDepartment}
              data={this.props.departmentDetails}
              showModalPopUp={this.addParticipantsDepartment}
              selectedParticipant={this.state.selectedParticipant}
              companyAuthDetails={companyDetails}
            />
          </Suspense>
        )}
        <div>
          {printAttendees && (
            <CSVList
              Title={`Active-Employees`}
              removeCSVData={this.printAction}
              data={employeeData}
              headers={headers}
            />
          )}
        </div>

        {downloadCSV && !_.isUndefined(csvData) && !_.isNull(csvData) && (
          <CSVMaker data={csvData} removeCSVData={removeCSVData} />
        )}
        <ReactPDF
          showModal={showPDFModal}
          closeModal={this.closePopup}
          pdfBase64={pdfBase64}
          buttonText={buttonText}
          employee={selectedEmployeeDetail[0]}
          removePDF={this.removePDF}
          showCrossMarker={true}
        />
        {showRolesPopup && (
          <Suspense fallback={<Waiting />}>
            {" "}
            <AddRolesPopup
              showModal={showRolesPopup}
              closeModal={this.onToggleRolesPopup}
              employee={selectedEmployeeDetail && selectedEmployeeDetail[0]}
              userRolesList={userRolesList}
              companyId={companyInfo.id}
            />
          </Suspense>
        )}
        {showUserPopUp ? (
          <Suspense fallback={<Waiting />}>
            <EditUserDetailsPopup
              showUserModal={showUserPopUp}
              onClose={this.userModal}
              employee={selectedEmployeeDetail && selectedEmployeeDetail[0]}
              locationDetails={this.props.locationDetails}
              departmentDetails={this.props.departmentDetails}
              fetchEmployee={this.fetchEmployee}
            />{" "}
          </Suspense>
        ) : null}
        {this.notePopup()}
      </Layout>
    );
  }
}

PeoplePage.defaultProps = {
  companyInfo: { id: 1, name: "" },
};

PeoplePage.propTypes = {
  fetchListOfEmployees: PropTypes.func.isRequired,
  employeeList: PropTypes.array,
  fetchSortedEmployees: PropTypes.func.isRequired,
  sortedList: PropTypes.array,
  history: PropTypes.object,
  employeeData: PropTypes.array,
  count: PropTypes.number,
  companyInfo: PropTypes.object,
  companies: PropTypes.array,
  fetchPeopleAdmin: PropTypes.func,
  postAddEmployee: PropTypes.func,
  fetchSetRemoveAPI: PropTypes.func,
  deleteEmployeeAPI: PropTypes.func,
  fetchEmployeeCount: PropTypes.func,
  setCompany: PropTypes.func,
  downloadCSV: PropTypes.bool,
  csvData: PropTypes.array,
  removeCSVData: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  paginationCount: PropTypes.number,
  searchAmigo: PropTypes.bool,
  uploadBiometricForm: PropTypes.func,
  role: PropTypes.string,
  viewPhysicianFormAPI: PropTypes.func,
  biometricPdfFile: PropTypes.string,
  userPermissions: PropTypes.array,
  userRolesList: PropTypes.array,
  getUserRoles: PropTypes.func,
  getCompanyDetails: PropTypes.func,
  companyDetails: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  fetchFriendRequests: PropTypes.func.isRequired,
  friendRequests: PropTypes.array,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  getWellnessInterests: PropTypes.func,
  wellnessInterests: PropTypes.array,
  showView: PropTypes.bool,
  fetchEditLocationApi: PropTypes.func,
  fetchEditDepartmentApi: PropTypes.func,
  userCompany: PropTypes.object,
};

const mapStateToProps = (state) => ({
  employeeList: state.peopleHome.employeeList,
  sortedList: state.peopleHome.sortedList,
  employeeData: state.peopleHome.employeeAdminData,
  count: state.peopleHome.count,
  downloadCSV: state.peopleHome.csvMake,
  csvData: state.peopleHome.csvData,
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  paginationCount: state.peopleHome.paginationCount,
  biometricPdfFile: state.profileData.biometricPdfFile,
  userPermissions: state.profileData.userPermissions,
  userRolesList: state.peopleHome.userRolesList,
  companyDetails: state.peopleHome.companyDetails,
  sentRequest: state.social.sentRequest,
  friendRequests: state.social.friendRequests,
  wellnessInterests: state.register.wellnessInterests,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListOfEmployees: () => dispatch(getListOfEmployees()),
  fetchSortedEmployees: (search, engagement, department, alphabetical) =>
    dispatch(getSortedEmployees(search, engagement, department, alphabetical)),
  fetchPeopleAdmin: (data, cb) => dispatch(fetchPeopleAdmin(data, cb)),
  postAddEmployee: (data) => dispatch(PostAddEmployee(data)),
  fetchSetRemoveAPI: (obj) => dispatch(FetchSetRemoveAPI(obj)),
  deleteEmployeeAPI: (obj) => dispatch(DeleteEmployeeAPI(obj)),
  fetchEmployeeCount: (obj, bool) => dispatch(FetchEmployeeCount(obj, bool)),
  removeCSVData: () => dispatch(RemoveCSVData()),
  uploadBiometricForm: (obj, cb, userId, fetchEmployee) =>
    dispatch(uploadBiometricForm(obj, cb, userId, fetchEmployee)),
  viewPhysicianFormAPI: (uid, cb) => dispatch(viewPhysicianFormAPI(uid, cb)),
  getUserRoles: (uid) => dispatch(getUserRoles(uid)),
  getCompanyDetails: (companyId) => dispatch(getCompanyDetails(companyId)),
  sendFriendRequest: (data, obj) => dispatch(sendFriendRequest(data, obj)),
  fetchFriendRequests: () => dispatch(getFriendRequests()),
  acceptOrRejectFriendRequest: (acceptData) =>
    dispatch(acceptOrRejectFriendRequest(acceptData)),
  getWellnessInterests: () => dispatch(getWellnessInterests()),
  fetchEditLocationApi: (data, deleteloc) =>
    dispatch(editLocationApi(data, deleteloc)),
  fetchEditDepartmentApi: (data, deletedept) =>
    dispatch(editDepartmentApi(data, deletedept)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PeoplePage));
